import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, Navigate } from 'react-router-dom';
import CustomCursor from './CustomCursor';
import Bairon from './Bairon';
import Projects from './Projects';
import LoadingScreen from './LoadingScreen';
import './App.css';

function Overlay() {
  const location = useLocation();

  if (location.pathname === '/') {
    return <div className="name-overlay">BAIRON&nbsp;RECINOS</div>;
  }
  return null;
}

function App() {
  return (
    <Router>
      <div className="App">
        <LoadingScreen />
        <CustomCursor />
        <nav className="nav-bar">
          <Link to="#">ABOUT</Link>
          <a href="https://github.com/baironrecinos?tab=repositories" target="_blank" rel="noopener noreferrer">PROJECTS</a>
          <Link to="#">RESUME</Link>
        </nav>
        <div className="content">
          <Routes>
            <Route path="/" element={<Bairon />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Overlay />
        <footer className="footer">
          <a href="https://www.linkedin.com/in/baironrecinos/" target="_blank" rel="noopener noreferrer">LINKEDIN</a>
          <a href="https://github.com/baironrecinos" target="_blank" rel="noopener noreferrer">GITHUB</a>
        </footer>
      </div>
    </Router>
  );
}

export default App;